import classNames from 'classnames/bind';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import * as styles from './Picto.module.scss';

const c = classNames.bind(styles);

function Picto({
	image,
	color = 'orange',
	className,
	withDash = false,
	children,
	index,
}) {
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 1,
		rootMargin: index ? -(index * 2) + '%' : '0px',
	});

	return (
		<div className={c('wrapper', color, className, { inView })} ref={ref}>
			{children ? (
				React.cloneElement(children, { className: c('picto') })
			) : (
				<img
					loading="lazy"
					src={image.url}
					alt={image.title}
					className={c('picto')}
				/>
			)}
			{withDash && <div className={c('dash')}></div>}
		</div>
	);
}

export default Picto;
