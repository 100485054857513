import classNames from 'classnames/bind';
import React from 'react';
import Picto from '../Picto/Picto';
import * as styles from './GridPicto.module.scss';

const c = classNames.bind(styles);

function GridPicto({ className, data }) {
	return (
		<section className={c('wrapper', className)}>
			<h2 className={c('label')}>{data.title}</h2>
			<div className={c('container')}>
				{data.items && data.items.map((item, index) => (
					<section className={c('picto')} key={index}>
						<Picto image={item.image} color={data.color} withDash={true} index={index}/>
						<h3
							className={c('title')}
							dangerouslySetInnerHTML={{ __html: item.title }}
						></h3>
						<p
							className={c('text')}
							dangerouslySetInnerHTML={{ __html: item.description }}
						></p>
					</section>
				))}
			</div>
		</section>
	);
}

export default GridPicto;
