import classNames from 'classnames/bind';
import React from 'react';
import * as styles from './Job.module.scss';

const c = classNames.bind(styles);

function Job({ className, data }) {
	return (
		<li className={c('wrapper', className)}>
			<a href={data.uri}>
				<span className={c('text')}>
					{data.acfSingleJob.key_informations.location}
				</span>
				<h3
					className={c('title')}
					dangerouslySetInnerHTML={{ __html: data.title }}
				></h3>
				<span className={c('text')}>
					{data.acfSingleJob.key_informations.contract}
				</span>
			</a>
		</li>
	);
}

export default Job;
