import classNames from 'classnames/bind';
import { graphql } from 'gatsby';
import React from 'react';
import DoubleColText from '../../components/common/DoubleColText/DoubleColText';
import GridPicto from '../../components/common/GridPicto/GridPicto';
import Intro from '../../components/Intro/Intro';
import JobsList from '../../components/Jobs/JobsList/JobsList';
import Seo from '../../components/Seo/Seo';
import * as styles from './Careers.module.scss';

const c = classNames.bind(styles);

export const query = graphql`
	query($id: String!, $lang: WpLanguageCodeEnum) {
		page: wpPage(id: { eq: $id }, language: { code: { eq: $lang } }) {
			seo {
				title
				metaDesc
				opengraphTitle
				opengraphDescription
				opengraphSiteName
				opengraphImage {
					uri
				}
				twitterTitle
				twitterDescription
				twitterImage {
					uri
				}
			}
			translations {
				uri
				language {
					locale
				}
			}
			acf: acfPageCareers {
				header {
					bgtype
					description
					image {
						url
						title
					}
					image_mobile {
							title
							url
					}
					cover {
						filesize
						height
						mime_type
						title
						url
						width
					}
					vimeoId: popin_video
					title
					video {
						url
						type
						title
					}
				}
				pictogrammes {
					title
					color
					items {
						description
						title
						image {
							url
							title
						}
					}
				}
				text {
					title
					text2
					text
					headline
				}
			}
		}
		jobs: allWpJob(filter: { language: { code: { eq: $lang } } }) {
			edges {
				node {
					title
					uri
					acfSingleJob {
						block_text {
							items {
								text
								title
							}
						}
						header {
							image {
								filesize
								height
								mime_type
								title
								url
								width
							}
							image_mobile {
									filesize
									height
									mime_type
									title
									url
									width
							}
							vimeoId: popin_video
							video {
								filesize
								mime_type
								subtype
								title
								type
								url
							}
							title
							description
							bgtype
						}
						key_informations {
							contract
							location
						}
					}
				}
			}
		}
	}
`;

export default function Careers({ data }) {
	const { seo, translations, acf } = data.page;
	const jobs = data.jobs.edges;
	return (
		<main className={c('wrapper')}>
			<Seo {...{ seo, translations }} />
			<Intro data={acf.header} />
			<DoubleColText data={acf.text} />
			<GridPicto data={acf.pictogrammes} />
			<JobsList jobs={jobs} />
		</main>
	);
}
