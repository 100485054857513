import classNames from 'classnames/bind';
import React from 'react';
import * as styles from './DoubleColText.module.scss';

const c = classNames.bind(styles);

function DoubleColText({ className, data }) {
	return (
		<section className={c('wrapper', className)}>
			<p className={c('label')}>{data.headline}</p>
			<h2
				className={c('title')}
				dangerouslySetInnerHTML={{ __html: data.title }}
			/>
			<section
				className={c('text', 'first')}
				dangerouslySetInnerHTML={{ __html: data.text }}
			/>
			<section
				className={c('text', 'second')}
				dangerouslySetInnerHTML={{ __html: data.text2 }}
			/>
		</section>
	);
}

export default DoubleColText;
