import classNames from 'classnames/bind';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { LanguageContext } from '../../../utils/LanguageContext';
import Job from './Job/Job';
import * as styles from './JobsList.module.scss';
const c = classNames.bind(styles);

function JobsList({ className, jobs }) {
	const language = useContext(LanguageContext);
	const query = useStaticQuery(graphql`
		query {
			wp {
				sp {
					strings {
						list_jobs_title {
							en
							fr
						}
					}
				}
			}
		}
	`);
	const strings = query.wp.sp.strings.list_jobs_title;
	const bubble = useRef(null);
	const cpt = useRef(null);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		const updateHeight = () => {
			setHeight(bubble.current.clientHeight);
		};
		updateHeight();
		window.addEventListener('resize', updateHeight);
		return () => {
			window.removeEventListener('resize', updateHeight);
		};
	}, []);
	return (
		<section
			className={c('wrapper', className)}
			ref={cpt}
			style={{ minHeight: height }}
		>
			<img
				alt="Sable"
				ref={bubble}
				loading="lazy"
				className={c('round')}
				src="/images/jobs/sphere-sable-orange.png"
			/>
			<div className={c('containerContent')}>
				<h2 className={c('title')}>
					{language.languageCode === 'FR' ? strings.fr : strings.en}
				</h2>
				<ul className={c('containerJobs')}>
					{jobs.map((item, index) => (
						<Job data={item.node} key={index} />
					))}
				</ul>
			</div>
		</section>
	);
}

export default JobsList;
